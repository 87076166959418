@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");

@font-face {
  font-family: "AlteHaasGrotesk Bold";
  src: url("../alte_haas_grotesk/AlteHaasGroteskBold.ttf") format("truetype");
}
@font-face {
  font-family: "AlteHaasGrotesk Light";
  src: url("../alte_haas_grotesk/AlteHaasGroteskRegular.ttf") format("truetype");
}
$body-bg: #fff;
$body-color: #212529;

$theme-colors: (
  "green": #33a563,
  "yellow": #fbf977,
  "main-container": #222,
  "navbar-color": #565655,
  "site-table-color": #d6d5d3,
  "form-color": #565655,
  "input-color": #929291,
  "kopie-color": #e4e5e5,
  "footer-color": #868686,
  "p-color": #c5c6c6,
);

// 48cae4
@import "node_modules/bootstrap/scss/bootstrap";

html {
  font-size: 18px;
  @media (max-width: 1799.98px) {
    font-size: 17px;
  }
  @media (max-width: 1399.98px) {
    font-size: 16px;
  }
  @media (max-width: 1199.98px) {
    font-size: 13px;
  }
  @media (max-width: 991.98px) {
    font-size: 12px;
  }
  @media (max-width: 567.98px) {
    font-size: 11px;
  }
}
$weights: 100 200 300 400 500 600 700 800;
@each $weight in $weights {
  .fw--#{$weight} {
    font-weight: $weight;
  }
}
@for $size from 12 through 96 {
  .fs--#{$size} {
    font-size: $size/16 + rem;
  }
}
.pointer-cursor {
  cursor: pointer !important;
}
.form-Input::placeholder {
  color: #c5c6c6 !important;
}
.font-family-light {
  font-family: "AlteHaasGrotesk Light" !important;
}
.font-family-bold {
  font-family: "AlteHaasGrotesk Bold" !important;
}
.sidebar-logo {
  flex-basis: 15% !important;
}
.sideBar-text {
  flex-basis: 85% !important;
}
.kopieSection {
  background-image: url("../img/Kopie.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  &_text {
    // 	// @media only screen and (min-width: 768px) {
    // 	// 	position: absolute;
    // 	// }
    // 	// @media only screen and (max-width: 768px) {
    // 	// 	position: relative;
    // 	// }
    // 	position: relative;
    // 	margin-bottom: -10% !important;
    // 	@media (max-width: 587px) {
    // 		position: relative;
    // 		margin-bottom: 3% !important;
    // 	}
    &_button {
      @media only screen and (max-width: 587px) {
        width: 159px;
        font-size: 8px;
        height: 30px;
        img {
          // width: 19px;
          width: 11px;
        }
      }
    }
  }
}
.label {
  height: 10%;
  width: 10%;
}
.form-height {
  height: 30rem;

  @media (max-width: 576px) {
    height: 34rem !important;
  }

  /* Landscape phones and portrait tablets */
  @media (max-width: 768px) {
    height: 30rem;
  }
  // @media (min-width: 1200px) {
  // 	height: 25rem;
  // }
}
.form-height-CrowdFund {
  height: 30rem;

  // @media (max-width: 576px) {
  // 	height: 30rem !important;
  // }

  // /* Landscape phones and portrait tablets */
  // @media (max-width: 768px) {
  // 	height: 30rem;
  // }
}
.btn-hover:hover {
  background-color: #fff163 !important;
}
.arrowInformatie {
  @media only screen and (max-width: 587px) {
    width: 19px !important;
  }
}
.person-img {
  @media (min-width: 768px) {
    height: auto !important;
    width: auto !important;
    padding: auto !important;
  }
  // @media (min-width: 1799px) {
  // 	height: 100% !important;
  // 	width: auto !important;
  // 	padding: auto !important;
  // }
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  //   border: none !important;
  border-color: #ced4da;
  background-image: none !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 254, 0.25);
  //   box-shadow: none;
}
.form-control:required:is-invalid {
  //   border: none !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat;
  box-shadow: #808080 !important;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
