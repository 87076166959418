// #E9E276

$theme-colors: (
  "site-yellow": #fbf977,
);
.fs--11 {
  font-size: 11px;
}
.fs--10 {
  font-size: 10px;
}
.h-22 {
  height: 22px !important;
}
.font-family-MonSerrat {
  font-family: "Montserrat";
}
.font-family-OpenSan {
  font-family: "Open Sans";
}
.headerSection2 {
  background-image: url("../img/Header_image3(2).jpg") !important;
  background-size: auto 100%;
}
.headerSection {
  background-image: url("../img/Ibis-de-Haan2.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover !important;
  &__topDiv {
    // opacity: 0.8;
    margin-left: 15%;
    // height: 477px;
    // height: 493px;
    width: 384px !important;
    background-color: #c6c6c6cc;

    @media screen and (max-width: 520px) {
      margin-left: 0%;
      width: 100% !important;
      // height: 430px;
    }
    @media screen and (max-width: 375px) {
      margin-left: 0%;
      // height: 410px;
      // height: 356px;
      width: 100% !important;
    }

    &--yellowDiv {
      // height: 75px;
      font-size: 16px;
      letter-spacing: 0px;
      color: #000000;
      background-color: #fbf977 !important;
      font-weight: 800;
      font-family: "Montserrat";
      text-align: center;
    }
    @media screen and (max-width: 375px) {
      font-size: 13px !important;
    }

    &--fonts {
      font-size: 18px;
      letter-spacing: 0px;
      color: #000000;
      font-weight: 700;
      margin: 0px 12px;
      font-family: "Montserrat";
      // text-align: justify;
      padding: 3px;
    }
    @media screen and (max-width: 375px) {
      font-size: 12px !important;
    }
  }
  &__formDiv {
    // height: 486px;
    background-color: #565655;
    margin-left: 15%;
    width: 384px;
    h3 {
      margin: 0 10px;
      font-family: "Montserrat";
      font-size: 22px;
      font-weight: 600;
    }
    @media screen and (max-width: 768px) {
      // height: 460px;
    }
    @media screen and (max-width: 520px) {
      margin-left: 0%;
      width: 100% !important;
      // height: 391px;
    }
    @media screen and (max-width: 375px) {
      margin-left: 0%;
      // height: 450px;
      // height: 399px;
      width: 100% !important;
    }
    @media screen and (min-width: 1024px) {
      // height: 612px;
      background-color: #565655;
      margin-left: 15%;
      width: 384px;
    }
    // 1440
    @media screen and (min-width: 1440px) {
      // height: 657px;
      background-color: #565655;
      margin-left: 15%;
      width: 384px;
    }
    @media screen and (min-width: 2560px) {
      // height: 706px;
    }
  }
  i {
    // margin-bottom: 23px;
    // padding-right: 10px;
    margin-bottom: 8px;
    padding-top: 3px;
    padding-right: 10px;
  }
  button {
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    border: none;
    width: 270px;
    border-radius: 0%;
    // background: #fff163;
    color: darkblue;
    height: 44px;
  }
}

.botton_vragaan {
  filter: drop-shadow(2.65px 4.24px 3px rgba(0, 0, 0, 0.64));
  // background-image: linear-gradient(
  //   90deg,
  //   #fff163 0%,
  //   #f8e311 100%,
  //   #f8e311 100%
  // );
  background-color: #fbf977;
}
